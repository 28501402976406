<template>

  <div>
    <!-- Headline -->
    <HeroCurved :heading="$t('what_is_anytask.banner.heading')" :subheading="$t('what_is_anytask.banner.subheading')" />

    <div class="main-container p-0">
      <div class="container-fluid products pt-0 pt-md-2 px-0 px-md-3 mx-auto mt-5">
        <!-- AT -->
        <div class="d-flex flex-row pt-0 pt-md-3 mt-0 mb-0 mb-md-3 at">
          <!-- video -->
          <b-col class="image col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg" :src="require('@/assets/WhatIsAnyTask/for-a-freelancer.jpg')">
          </b-col>
          <!-- Copy -->
          <b-col class="col-12 col-md-8 d-flex flex-column align-items-start pl-3 pl-md-5 mt-4 m-md-0 px-4 px-md-0">
            <h3 class="font-weight-bold w-100">{{ $t('what_is_anytask.for_a_freelancer.heading')}}</h3>
            <h3 class="mb-4">{{ $t('what_is_anytask.for_a_freelancer.subheading') }}</h3>
            <p class="text-left mb-4" v-html="$t('what_is_anytask.for_a_freelancer.paragraph_1')"></p>
            <p class="text-left"
               v-html="$t('what_is_anytask.for_a_freelancer.paragraph_2', {'link-start': '' +
                       '<a href=\'/become-seller\' ' +
                          'class=\'sky\' rel=\'noopener noreferrer\'>', 'link-end':'</a>'})">
            </p>
          </b-col>
        </div>

        <!-- ETN -->
        <div class="d-flex flex-row pt-0 pt-md-5 pb-5 etn">
          <!-- image -->
          <b-col order-md="2" class="image image-buyer col-12 col-md-4 p-0 overflow-hidden">
            <img class="rounded-lg" :src="require('@/assets/WhatIsAnyTask/for-a-buyer.jpg')">
          </b-col>
          <!-- copy -->
          <b-col order-md="1" class="col-12 col-md-8 d-flex flex-column align-items-start pr-3 pt-3 pt-md-0 pr-md-5 px-4 px-md-0">
            <h3 class="font-weight-bold w-100">{{ $t('what_is_anytask.for_a_buyer.heading')}}</h3>
            <h3 class="mb-4">{{ $t('what_is_anytask.for_a_buyer.subheading') }}</h3>
            <p class="text-left mb-2" v-html="$t('what_is_anytask.for_a_buyer.paragraph_1')"></p>
            <p class="text-left mb-2" v-html="$t('what_is_anytask.for_a_buyer.paragraph_2')"></p>
            <p class="text-left mb-2" v-html="$t('what_is_anytask.for_a_buyer.paragraph_3')"></p>
            <p class="text-left mb-2" v-html="$t('what_is_anytask.for_a_buyer.paragraph_4')"></p>
          </b-col>
        </div>
      </div>

      <!-- lower banner -->
      <div id="lower-banner" class="w-100 position-relative d-flex flex-column justify-content-center align-items-center">
        <img :src="require('@/assets/WhatIsAnyTask/shapes-left.png')" class="position-absolute d-none d-md-flex">
        <img :src="require('@/assets/WhatIsAnyTask/shapes-right.png')" class="position-absolute d-none d-md-flex">
        <div class="content px-3 py-5">
          <h2 class="font-weight-bold mx-4 mx-sm-0">{{ $t('what_is_anytask.the_tasks_and_numbers.heading_1') }}</h2>
          <div class="d-flex flex-row justify-content-center mb-2">
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_1') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_2') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_3') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_4') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_5') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_6') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_7') }}</button>
            <button type="button" class="btn btn-outline-light mx-1 my-1">{{ $t('what_is_anytask.the_tasks_and_numbers.button_8') }}</button>
          </div>
          <p class="mb-5">{{ $t('what_is_anytask.the_tasks_and_numbers.more') }}</p>
          <div class="mb-3 mx-auto">
            <h2 class="font-weight-bold">{{ $t('what_is_anytask.the_tasks_and_numbers.heading_2') }}</h2>
          </div>
          <div class="d-flex flex-row justify-content-around">
            <div class="col-sm-12 col-md-4">
              <h3 class="font-weight-bold">{{ $t('what_is_anytask.the_tasks_and_numbers.stat_1') }}</h3>
              <p>{{ $t('what_is_anytask.the_tasks_and_numbers.stat_1_headline') }}</p>
            </div>
            <div class="col-sm-12 col-md-4">
              <h3 class="font-weight-bold">{{ $t('what_is_anytask.the_tasks_and_numbers.stat_2') }}</h3>
              <p>{{ $t('what_is_anytask.the_tasks_and_numbers.stat_2_headline') }}</p>
            </div>
            <div class="col-sm-12 col-md-4">
              <h3 class="font-weight-bold">{{ $t('what_is_anytask.the_tasks_and_numbers.stat_3') }}</h3>
              <p>{{ $t('what_is_anytask.the_tasks_and_numbers.stat_3_headline') }}</p>
            </div>

          </div>
        </div>
      </div>

      <div class="container col-12 col-md-6 d-flex flex-column align-items-center pr-3 pr-md-5 px-4 px-md-0 mt-4">
        <h3 class="font-weight-bold mb-4 mt-4 mt-md-0 w-100">{{ $t('what_is_anytask.how_it_works.heading') }}</h3>
        <p class="mb-4" v-html="$t('what_is_anytask.how_it_works.paragraph_1', {'link-start': '' +
                        '<a href=\'#signup\' ' +
                        'class=\'sky\' rel=\'noopener noreferrer\'>', 'link-end': '</a>'})"></p>
        <a class="btn btn-sky mx-auto px-4 w-100 text-wrap"
           href="https://news.anytask.com/a-best-practice-guide-for-anytask-freelancers"
           target="_blank"
           rel="noopener noreferrer">
          {{ $t('what_is_anytask.how_it_works.button_1') }}
        </a>
      </div>

      <div class="container col-12 col-md-6 d-flex flex-column align-items-center pr-3 pr-md-5 px-4 px-md-0 mt-4 pb-5">
        <p class="mb-4" v-html="$t('what_is_anytask.how_it_works.paragraph_2', {'link-start': '' +
                       '<a href=\'/\' ' +
                          'class=\'sky\' rel=\'noopener noreferrer\'>', 'link-end': '</a>'})"></p>
        <a class="btn btn-sky mx-auto px-4 w-100 text-wrap"
           href="https://news.anytask.com/how-to-find-the-right-online-freelancer-every-time-with-anytask"
           target="_blank"
           rel="noopener noreferrer">
          {{ $t('what_is_anytask.how_it_works.button_2') }}
        </a>
      </div>

      <!-- selling points -->
      <SellingPoints/>

    </div>
  </div>
</template>

<script>
import HeroCurved from '../components/Global/HeroCurved.vue'
import SellingPoints from '../components/Global/SellingPoints.vue'
import config from '../config'
import helper from '../helper'

export default {
  name: 'home',
  props: {
    image: Image,
    title: String,
    text: String
  },
  metaInfo () {
    return {
      title: 'AnyTask Platform - Buy or Sell digital Tasks',
      description: 'Access digital professionals or sell your skills to buyers across the world',
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'AnyTask Platform - Buy or Sell digital Tasks'
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: 'https://images.anytask.com/image/upload/b_rgb:ffffff,c_lpad,co_rgb:ffffff,f_jpg,h_630,w_1200/urbiknsbdw7r5mpntg5w.jpg'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Access digital professionals or sell your skills to buyers across the world'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: window.location.protocol + '//' + window.location.host + '/what-is-anytask'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:site_name',
          property: 'og:site_name',
          content: config.anytasks_name
        }
      ]
    }
  },
  data () {
    return {
    }
  },
  components: {
    HeroCurved,
    SellingPoints
  },
  watch: {
    $route (to, from) {
      if (to.hash === '#signup') {
        if (this.$store.state.accessToken != null) {
          this.$router.push({ name: 'connecting-etn' })
        } else {
          this.$bvModal.show('signup')
          window.location.hash = ''
        }
      }
    }
  },
  mounted () {
    this.scrollMeTo()
    helper.reorderMetas()
  },
  methods: {
    scrollMeTo () {
      let acceptedHash = [
        '#buy',
        '#no-account',
        '#zero-fees'
      ]
      this.$nextTick(() => {
        if (acceptedHash.includes(location.hash)) {
          var nav = document.getElementById('NavBar').offsetHeight
          var element = document.getElementById(location.hash)
          var top = element.offsetTop - nav
          window.scrollTo(0, top)
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .sky {
    color: $brand-sky;
  }
</style>

<style lang="scss" scoped>

  #content {
    background-color: white !important;
  }

  #meet-the-freelancer {
    background-image: url('~@/assets/BecomeASeller/bg-meet-the-freelancer.jpg') !important;
    @include md-up {
      background-image: url('~@/assets/BecomeASeller/bg-meet-the-freelancer-xl.jpg') !important;
      background-position: 66% center;
      background-size: cover;
    }
  }

  .anytask-protection {
    font-size: 14px;
  }

  .products {
    a {
      color: $brand-sky !important;
    }
  }

  .btn-sky {
    background: transparent;
    border-color: $brand-sky !important;
    color: $brand-sky !important;
    &:hover {
      color: white !important;
      background: $brand-sky !important;
    }
  }
  .main-container {
    .container-fluid {
      max-width: 1140px !important;
    }
    .products {
      p {
        line-height: 1.675rem;
      }
      .at {
        .features {
          font-size: 14px;
          img {
            width: 24px;
          }
        }
      }
      .etn {
        .image {
          background-size: cover;
          .logo {
            width: 200px;
          }
        }
      }
    }
  }

  #lower-banner {
    background: $brand-navy;
    min-height: 260px;
    color: white;
    overflow: hidden;
    .content {
      z-index: 999;
    }
    img {
      height: 100%;
      &:nth-child(1) {
        left: 0;
        top: 0;
        bottom: 0;
      }
      &:nth-child(2) {
        right: -50px;
        top: 0;
        bottom: 0;
      }
    }
    button {
      &:hover {
        background: transparent;
        color: white;
        cursor: auto;
      }
      &:active, &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .hero {
    background-image: $hero-mobile-shadow, url('~@/assets/WhatIsAnyTask/what-is-anytask-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-large.jpg');
    }
  }

  @include sm-up {
    .btn {
      width: auto !important;
    }
  }

  @include md-up {
    .main-container {
      .products {
        h3 {
          text-align: left;
        }
        .etn {
          .btn {
            width: auto !important;
          }
        }
      }
    }
  }
</style>
