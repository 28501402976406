<template>
  <div class="bg-primary main-container">
    <div class="container">
      <div class="row" v-lazy-container="{ selector: 'img' }">
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/money-back-icon.svg')" v-bind:alt="$t('home.selling_points.guarantee')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.guarantee')"/>
        </div>
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/payment-protection-icon.svg')" v-bind:alt="$t('home.selling_points.protection')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.protection')"/>
        </div>
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/translation-system-icon.svg')" v-bind:alt="$t('home.selling_points.translation')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.translation')"/>
        </div>
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/23-languages-icon.svg')" v-bind:alt="$t('home.selling_points.global_marketplace')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.global_marketplace')"/>
        </div>
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/support-team-icon.svg')" v-bind:alt="$t('home.selling_points.support')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.support')"/>
        </div>
        <div class="col-12 col-md-4 mt-3 selling-points d-flex flex-column align-items-center text-white mb-2">
          <img :data-src="require('@/assets/Home/skilled-talent-icon.svg')" v-bind:alt="$t('home.selling_points.talent_pool')" class="mb-1">
          <p class="mw-100 mb-0" v-html="$t('home.selling_points.talent_pool')"/>
        </div>
      </div>
    </div>
    <div class="pt-4 mb-0">
      <router-link :to="{ name: 'anytask_protection' }" class="text-white anytask-protection" v-html="$t('home.selling_points.protection_disclaimer')"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SellingPoints'
}
</script>
